<template>
  <div class="container">
    <NavBar />
    <div class="page_title">Tuya Api</div>

    <div class="flex_button">
      <div class="device_buttons">
        <div v-for="device in deviceDetails" :key="device">
          <v-btn
            density="compact"
            class="tuya_btn room_button"
            variant="outlined"
            :color="getColour(device.deviceID)"
            @click="viewTimes(device.deviceID)"
            >{{ device.deviceName.replace("bedroom", "bed") }}</v-btn
          >
        </div>
      </div>
      <div class="device_buttons">
        <div v-show="search != 'xxx'">
          <v-btn
            density="compact"
            class="tuya_btn room_button"
            variant="outlined"
            @click="updateTimes()"
            >Update Times</v-btn
          >
        </div>
        <v-btn
          density="compact"
          class="tuya_btn room_button"
          variant="outlined"
          @click="defaultTimes()"
          >Set default</v-btn
        >
      </div>
    </div>
    <div v-show="search != 'xxx'" class="time_table">
      <v-data-table
        height="60vh"
        fixed-header
        :headers="fields"
        :items="deviceDetails"
        :search="search"
      >
        <template v-slot:item.weekdayTimes="{ item }">
          <div v-if="item.columns.deviceID == search">
            <div v-for="time in item.selectable.weekdayTimes" :key="time">
              {{ time.startTime }} to {{ time.endTime }}
              <v-btn
                class="tuya_btn"
                variant="text"
                @click="
                  changeTemp(
                    'WD',
                    item.columns.deviceID,
                    time.startTime,
                    time.tempSet,
                    1
                  )
                "
                >↑</v-btn
              >
              <v-btn
                class="tuya_btn"
                variant="text"
                @click="
                  changeTemp(
                    'WD',
                    item.columns.deviceID,
                    time.startTime,
                    time.tempSet,
                    -1
                  )
                "
                >↓</v-btn
              >{{ time.tempSet }}
            </div>
          </div>
        </template>
        <template v-slot:item.weekendTimes="{ item }">
          <div v-for="time in item.selectable.weekendTimes" :key="time">
            {{ time.startTime }} to {{ time.endTime }}
            <v-btn
              class="tuya_btn"
              variant="text"
              @click="
                changeTemp(
                  'WE',
                  item.columns.deviceID,
                  time.startTime,
                  time.tempSet,
                  1
                )
              "
              >↑</v-btn
            >
            <v-btn
              class="tuya_btn"
              variant="text"
              @click="
                changeTemp(
                  'WE',
                  item.columns.deviceID,
                  time.startTime,
                  time.tempSet,
                  -1
                )
              "
              >↓</v-btn
            >{{ time.tempSet }}
          </div>
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "Settings",
  components: { NavBar, VDataTable },
  data() {
    return {
      currentDevice: "",
      currentDeviceID: null,
      currentDeviceStatus: "",
      search: "xxx",
      deviceDetails: [],
      deviceDetailFiltered: [],
      fields: [
        { key: "deviceID", title: "ID", sortable: false, align: " d-none" },
        { key: "weekdayTimes", title: "Weekday Times", sortable: true },
        { key: "weekendTimes", title: "Weekend Times", sortable: true },
      ],
      backendurl: process.env.VUE_APP_API,
    };
  },
  created() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.deviceDetails = [];
      var postdata = {
        module: "settings",
        action: "allSettings",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          this.deviceDetails = res.data;
        }
      });
    },
    getColour(deviceID) {
      if (deviceID == this.currentDeviceID) {
        return "red";
      }
      return;
    },
    updateTimes() {
      var postdata = {
        module: "settings",
        action: "timeSettings",
        key: this.$store.state.authKey,
        detail: { deviceDetails: this.deviceDetails },
      };
      Axios.post(this.backendurl, postdata, this.headers).then(() => {
        this.getSettings();
      });
    },
    viewTimes(device) {
      const index = this.deviceDetails.findIndex((e) => e.deviceID == device);
      if (index != -1) {
        this.currentDeviceID = this.deviceDetails[index].deviceID;
        this.currentDevice = this.deviceDetails[index].deviceName;
        this.currentDeviceStatus =
          this.deviceDetails[index].deviceStatus == "y"
            ? "Active"
            : "Holiday mode";
        this.search = device;
      }
    },
    defaultTimes() {
      var postdata = {
        module: "settings",
        action: "defaultSettings",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then(() => {
        this.getSettings();
        this.currentDeviceStatus = null;
      });
    },
    hoidayMode() {
      var postdata = {
        module: "settings",
        action: "holidaySettings",
        key: this.$store.state.authKey,
        detail: {
          deviceID: this.currentDeviceID,
          deviceStatus: this.currentDeviceStatus,
        },
      };
      Axios.post(this.backendurl, postdata, this.headers).then((response) => {
        this.getSettings();
        this.currentDeviceStatus = response.data.currentStatus;
      });
    },
    changeTemp(type, deviceID, startTime, temp, change) {
      const deviceIndex = this.deviceDetails.findIndex(
        (e) => e.deviceID == deviceID
      );
      if (deviceIndex != -1) {
        if (type == "WD") {
          let deviceTimes = this.deviceDetails[deviceIndex].weekdayTimes;
          const timeIndex = deviceTimes.findIndex(
            (e) => e["startTime"] == startTime
          );
          if (timeIndex != -1) {
            for (let i = timeIndex; i < deviceTimes.length; i++) {
              deviceTimes[i]["tempSet"] = temp + change;
            }
          }
        } else {
          let deviceTimes = this.deviceDetails[deviceIndex].weekendTimes;
          const timeIndex = deviceTimes.findIndex(
            (e) => e["startTime"] == startTime
          );
          if (timeIndex != -1) {
            for (let i = timeIndex; i < deviceTimes.length; i++) {
              deviceTimes[i]["tempSet"] = temp + change;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
.tuya_btn {
  background-color: lightgray;
  margin: 3px;
}
.page_item {
  margin-top: 20px;
}
.page_item_buttons {
  display: flex;
  gap: 20px;
}
.device_table {
  border: 1px grey solid;
}
.info_section {
  margin-top: 10px;
}
.info_section_flex {
  display: flex;
}
.top_align {
  vertical-align: top;
  height: 100%;
}
.flex_button {
  display: flex;
  justify-content: space-between;
}
.device_buttons {
  display: flex;
}
.time_table {
  border: 1px solid grey;
  margin: 20px;
}
@media only screen and (max-width: 500px) {
  .room_button {
    font-size: 10px;
  }
  .flex_button {
    flex-direction: column;
  }
}
</style>
