<template>
  <div class="container">
    <NavBar />
    <div class="page_title">Tuya Api</div>
    <div class="info_section info_buttons">
      <v-btn class="tuya_btn" variant="text" @click="auth()">
        {{ accessToken == "" ? "Get access token" : "Refresh token" }}</v-btn
      >
      <v-btn class="tuya_btn" variant="text" @click="reinitialise()">
        Get data</v-btn
      >
    </div>
    <div v-if="accessToken != '' && loaded">
      <div class="info_section">Device Information</div>
      <div class="info_section">
        <div class="device_table">
          <v-data-table fixed-header :headers="fields" :items="deviceDetails">
            <template v-slot:item.deviceActive="{ item }">
              <v-btn class="tuya_btn" variant="text" @click="active(item)">{{
                item.columns.deviceActive == "y" ? "Active" : "Off"
              }}</v-btn>
            </template>
            <template v-slot:item.deviceStatus="{ item }">
              <v-btn class="tuya_btn" variant="text" @click="device(item)">{{
                item.columns.deviceStatus == "On" ? "Turn Off" : "Turn On"
              }}</v-btn>
            </template>
            <template #bottom></template>
          </v-data-table>
        </div>
      </div>
    </div>

    <div class="info_section">API: {{ backendurl }}</div>
    <div class="info_section">Access token: {{ accessToken }}</div>
    <div class="info_section">Status: {{ currentStatus }}</div>
    <div class="info_section">Current Tariff: {{ currentTariff }}</div>

    <div class="info_section">
      <v-btn class="tuya_btn" variant="text" @click="cron()">
        Test Cron Job</v-btn
      >
    </div>

    <div class="info_section response_section">
      Response:
      <pre>{{ message }}</pre>
    </div>
  </div>
</template>

<script>
import NavBar from "./Navbar/Navbar.vue";
import Axios from "axios";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "Tuya_API",
  components: { NavBar, VDataTable },
  data() {
    return {
      currentTariff: null,
      loaded: false,
      authKey: "",
      currentStatus: "",
      deviceInfos: [],
      tempNumbers: [],
      backendurl: process.env.VUE_APP_API,
      temp_current: null,
      temp_set: null,
      temp_mode: null,
      deviceStatus: null,
      apiCode: "switch",
      apiValue: true,
      method: "",
      commands: null,
      fields: [
        { key: "deviceName", title: "Device Name", sortable: true },
        { key: "deviceID", title: "ID", sortable: false, align: " d-none" },
        { key: "deviceType", title: "Type", sortable: false, align: " d-none" },

        { key: "deviceActive", title: "Active", sortable: false },
        { key: "deviceStatus", title: "Status", sortable: false },
        {
          key: "deviceCurrentTemp",
          title: "Current Temp",
          sortable: false,
        },
        { key: "deviceSetTemp", title: "Set Temp", sortable: false },
        { key: "deviceMode", title: "Mode", sortable: false },
      ],
      stringToSignUrl: "",
      deviceID: "",
      deviceDetails: [],
      deviceIDs: [
        { value: "0487871598cdac3b810b", text: "Kitchen", type: "panel" },
        {
          value: "11007250c45bbe7aea23",
          text: "Master bedroom",
          type: "panel",
        },
        {
          value: "bfb38aefd9b48d7192zikw",
          text: "Second bedroom",
          type: "panel",
        },
        {
          value: "bfed3f6c0e7aa4786am2fz",
          text: "Kitchen floor",
          type: "floor",
        },
        {
          value: "153427843c6105f11679",
          text: "Bathroom floor",
          type: "floor",
        },
      ],
      accessToken: "",
      status: null,
      headers: [],
      action: "",
      easy_sign: "",
      message: null,
      baseUrl: "https://openapi.tuyaeu.com",
      tuyaApi: null,
      url: "",
    };
  },
  created() {
    for (var i = 5; i < 25; i++) {
      this.tempNumbers.push(i);
    }
  },
  mounted() {
    this.accessToken = this.$store.state.accessToken;
    if (this.accessToken != "") {
      this.initialise();
    } else {
      this.auth();
      this.initialise();
    }
  },
  methods: {
    async auth() {
      this.message = null;
      this.deviceInfos = [];
      this.deviceDetails = [];
      this.currentStatus = "Getting access token";
      this.action = "auth";
      this.stringToSignUrl = "/v1.0/token?grant_type=1";
      var postdata = {
        module: "tuya",
        action: "auth",
        key: this.$store.state.authKey,
        detail: {
          baseUrl: this.baseUrl,
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          stringToSignUrl: this.stringToSignUrl,
        },
      };

      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          if (res.data.success === false) {
            this.message = res.data.message;
            return;
          }
          this.$store.commit("setAccessToken", res.data.result.access_token);
          this.accessToken = res.data.result.access_token;
          this.initialise();
        }
      });
    },
    reinitialise() {
      this.message = null;
      this.deviceInfos = [];
      this.deviceDetails = [];
      this.initialise();
    },
    initialise() {
      for (var i = 0; i < this.deviceIDs.length; i++) {
        const newItem = {
          deviceName: this.deviceIDs[i].text,
          deviceID: this.deviceIDs[i].value,
          deviceType: this.deviceIDs[i].type,
          deviceActive: "-",
          deviceStatus: "Off",
          deviceCurrentTemp: 0,
          deviceSetTemp: 0,
          deviceMode: "",
        };
        this.deviceDetails.push(newItem);
      }
      this.currentStatus = "Getting device info ";
      this.getTuyaData();
    },

    async getTuyaData() {
      this.deviceInfos = [];
      for (var i = 0; i < this.deviceIDs.length; i++) {
        const deviceID = this.deviceIDs[i].value;
        const deviceType = this.deviceIDs[i].type;
        const stringToSignUrl =
          "/v2.0/cloud/thing/{device_id}/shadow/properties".replace(
            "{device_id}",
            deviceID
          );
        await this.makeCall("get_info", stringToSignUrl, deviceID, deviceType);
      }
      this.message = this.deviceInfos;
      this.currentStatus = "Idle";
    },

    async makeCall(action, stringToSignUrl, deviceID, deviceType) {
      if (this.accessToken == "" || this.accessToken === null) {
        this.message = "No access token";
        return;
      }
      var postdata = {
        module: "tuya",
        key: this.$store.state.authKey,
        detail: {
          access_token: this.accessToken,
          deviceID: deviceID,
          deviceType: deviceType,
          baseUrl: this.baseUrl,
          clientId: this.clientId,
          clientSecret: this.clientSecret,
          stringToSignUrl: stringToSignUrl,
        },
      };

      postdata.action = action;
      if (action == "commands") {
        postdata.detail.commands = this.commands;
        this.currentStatus = "Sending command";
      }

      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        this.loaded = true;
        if (res.data) {
          if (res.data.success === false) {
            this.message = res.data;
            return;
          }
          this.currentTariff = res.data.tariff;
          const deviceID = res.data.deviceID;
          const index = this.deviceDetails.findIndex(
            (e) => e.deviceID == deviceID
          );
          if (index != -1) {
            if (action == "get_info") {
              const status = res.data.result.properties;
              for (var i = 0; i < status.length; i++) {
                if (status[i]["code"] == "Temp_current") {
                  this.deviceDetails[index].deviceCurrentTemp =
                    status[i]["value"];
                }
                if (status[i]["code"] == "RoomTemper") {
                  this.deviceDetails[index].deviceCurrentTemp =
                    status[i]["value"] / 10;
                }
                if (status[i]["code"] == "Temp_set") {
                  if (status[i]["value"] != "no_change") {
                    this.deviceDetails[index].deviceSetTemp =
                      status[i]["value"];
                  }
                }
                if (status[i]["code"] == "SetTemp") {
                  this.deviceDetails[index].deviceSetTemp =
                    status[i]["value"] / 10;
                }
                if (status[i]["code"] == "Switch") {
                  this.deviceDetails[index].deviceStatus = status[i]["value"]
                    ? "On"
                    : "Off";
                }
                if (status[i]["code"] == "power1") {
                  this.deviceDetails[index].deviceStatus = status[i]["value"]
                    ? "On"
                    : "Off";
                }
                if (status[i]["code"] == "Mode") {
                  if (status[i]["value"] != "no_change") {
                    this.deviceDetails[index].deviceMode = status[i]["value"];
                  }
                }
              }
              this.deviceDetails[index].deviceActive = res.data.active;
              this.deviceInfos.push(res.data);
            }
            if (action == "commands") {
              this.deviceDetails[index].deviceStatus =
                res.data.switch === "true" ? "On" : "Off";
              if (res.data.tempToSet != "no_change") {
                this.deviceDetails[index].deviceSetTemp = res.data.tempToSet;
              }
              if (res.data.mode != "no_change") {
                this.deviceDetails[index].deviceMode = res.data.mode;
              }
              this.message = res.data;
              this.currentStatus = "Idle";
            }
          }
        }
      });
    },

    device(e) {
      const deviceID = e.columns.deviceID;
      const deviceStatus = e.columns.deviceStatus;
      const deviceType = e.columns.deviceType;
      const stringToSignUrl =
        "/v2.0/cloud/thing/{device_id}/shadow/properties/issue".replace(
          "{device_id}",
          deviceID
        );

      if (deviceStatus == "On") {
        this.commands = { Switch: "false" };
      } else {
        this.commands = { Switch: "true" };
      }
      this.makeCall("commands", stringToSignUrl, deviceID, deviceType);
    },

    active(e) {
      const deviceID = e.columns.deviceID;
      const deviceActive = e.columns.deviceActive;
      this.message = "";
      this.currentStatus = "Updating settings";
      var postdata = {
        module: "settings",
        action: "updateActive",
        key: this.$store.state.authKey,
        detail: { deviceID: deviceID, deviceActive: deviceActive },
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          const index = this.deviceDetails.findIndex(
            (e) => e.deviceID == res.data.deviceID
          );
          if (index != -1) {
            this.deviceDetails[index].deviceActive = res.data.active;
          }
          this.message = res.data;
        }
        this.currentStatus = "Idle";
      });
    },

    cron() {
      this.message = "";
      this.currentStatus = "Running cron";
      var postdata = {
        module: "tuya_cron",
        action: "cron",
        key: this.$store.state.authKey,
        detail: {},
      };
      Axios.post(this.backendurl, postdata, this.headers).then((res) => {
        if (res.data) {
          for (var i = 0; i < res.data.infoRequestResult.length; i++) {
            const result = res.data.infoRequestResult[i];
            const index = this.deviceDetails.findIndex(
              (e) => e.deviceID == result.deviceID
            );
            if (index != -1) {
              this.deviceDetails[index].deviceStatus =
                result.switch === "true" ? "On" : "Off";
              // this.deviceDetails[index].deviceCurrentTemp = result.Temp_current;
              if (result.tempToSet != "no_change") {
                this.deviceDetails[index].deviceSetTemp = result.tempToSet;
              }
              if (result.mode != "no_change") {
                this.deviceDetails[index].deviceMode = result.mode;
              }
            }
          }
          this.currentTariff = res.data.tariff;
          this.message = res.data;
        }

        this.currentStatus = "Idle";
      });
    },
  },
};
</script>

<style scoped>
.page_title {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.container {
  margin: 20px;
}
.tuya_btn {
  background-color: lightgray;
}
.page_item {
  margin-top: 20px;
}
.page_item_buttons {
  display: flex;
  gap: 20px;
}
.device_table {
  border: 1px grey solid;
}
.info_section {
  margin-top: 10px;
}
.response_section {
  border: 1px solid gray;
  overflow-x: auto;
}
.info_buttons {
  display: flex;
  gap: 20px;
}
</style>
